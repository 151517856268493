<template>
	<div>
		<!-- 内容 -->
		<div class="class_nav">
			<div class="nav_main">
				<div class="global-search-box">
					<div class="search-top-button" v-if="hasPerm(['goods.store'])">
						<el-button type="primary" @click="addclassedit" v-if="hasPerm(['goods.category.store'])">新增分类</el-button>
						<el-button type="primary" @click="addImportManage">批量上传</el-button>
					</div>
				</div>
				<div class="classifyTable">
					<!-- <div class="table_head">
						<el-select v-model="value" placeholder="批量设置">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div> -->
					<div class="table_list">
						<el-table :data="tableData" v-loading="isLoading"
							element-loading-background="rgba(255, 255, 255, 1)" element-loading-text="加载中，请稍后..."
							element-loading-spinner="el-icon-loading" style="width: 100%" ref="multipleTable"
							row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" type="index"
							@selection-change="singletable">
							<el-table-column width="120">
								<!-- <template slot="header" slot-scope="scope">
									<el-checkbox v-model="checkedAll" @change="changeAllSelect" />
								</template>
								<template slot-scope="scope">
									<el-checkbox :indeterminate="scope.row.indeterminate" v-model="scope.row.is_checked"
										@change="changeRowSelect(scope.row)">
									</el-checkbox>
								</template> -->
							</el-table-column>
							<el-table-column width="140" prop="id" label="ID"></el-table-column>
							<el-table-column label="分类名称" max-width="320" prop="name">
							</el-table-column>
							<el-table-column label="图标" max-width="220">
								<template slot-scope="scope">
									<div class="table_icon">
										<img :src="scope.row.pc_icon" alt="" :onerror="errorImg" />
									</div>
								</template>
							</el-table-column>
							<el-table-column label="商品数量" prop="cat_goods_count" max-width="220">
							</el-table-column>

							<el-table-column label="状态" prop="is_show" max-width="100">
								<template slot-scope="scope">
									<div>
										{{ scope.row.is_show==1?'显示':'隐藏' }}
									</div>
								</template>
							</el-table-column>
							<el-table-column label="排序" prop="sort" max-width="220">
							</el-table-column>
							<el-table-column label="操作" fixed="right" width="200">
								<template slot-scope="scope">
									<div class="caozuo">
										<a @click="catedit(scope.row.id)"
											v-if="hasPerm(['goods.category.update'])">修改</a>
										<a @click="catdelect(scope.row.id)"
											v-if="hasPerm(['goods.category.delete'])">删除</a>
									</div>
								</template>
							</el-table-column>
							<template slot="empty">
								<div class="empty_data">
									<p>暂无数据</p>
								</div>
							</template>
						</el-table>
					</div>
				</div>
			</div>
		</div>

		<!-- 导入分组 -->
		<diy-import-more ref="diyImportMore" dialogTitle="导入分组" dialogImportUrl="/cat/import"
			dialogTemplateUrl="category.import.template" @dialogImportCallback="importCallback"></diy-import-more>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
				options: [{
					label: "批量删除",
					value: "delete",
				}, ],
				tableData: [], //分类列表
				checkedAll: false,
				value: "",
				batchSuport: false,
				leadform: "",
				isLoading: false,
			};
		},
		components: {},
		created() {},
		mounted() {
			this.catlistapi();
		},
		methods: {
			// 打开导入弹窗
			addImportManage() {
				this.$refs.diyImportMore.importMore = true;
			},
			// 导入成功的回调
			importCallback() {
				// 成功导入，刷新列表
				this.catlistapi();
			},
			catlistapi() {
				this.isLoading = true;

				this.$get(this.$apis.infoList)
					.then((res) => {
						if (res.code == 200) {
							// console.log(res)
							this.isLoading = false;

							for (let i = 0; i < res.data.length; i++) {
								res.data[i].indeterminate = false;
							}
							this.tableData = res.data.list;
						} else {
							this.isLoading = false;

							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						this.isLoading = false;

						// console.log(err)
						this.common.message(this, err.message);
					});
			},
			// 删除分类
			catdelect(id) {
				// console.log(id)
				this.$delete(this.$apis.infoList + id)
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								duration: 1500,
								message: "删除成功",
								onClose: () => {
									this.catlistapi();
								},
							});
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// console.log(err)
						this.common.message(this, err.message);
					});
			},
			addclassedit() {
				this.$router.push({
					name: "class_edit",
					params: {
						id: 0,
					},
				});
			},
			catedit(id) {
				this.$router.push({
					name: "class_edit",
					params: {
						id: id,
					},
				});
			},
			// 单个表格被选中
			singletable(val) {
				this.multipleSelection = val;

				if (val.length == this.tableData.length) {
					this.all_checked = true;
					loop(val);
				} else {
					this.all_checked = false;
					loop(this.tableData);
				}
			},

			// 选择表格(全选)
			changeAllSelect(val) {
				const loop = (data) => {
					data.forEach((item) => {
						item.is_checked = val;
						if ("indeterminate" in item) {
							item.indeterminate = false;
						}
						if (item.children) {
							loop(item.children);
						}
					});
				};
				loop(this.tableData);
			},
			changeRowSelect(val) {
				if (val.children && val.children.length) {
					val.children.forEach((ss) => {
						ss.is_checked = val.is_checked;
						if (ss.children && ss.children.length) {
							ss.children.forEach((item) => {
								item.is_checked = ss.is_checked;
							});
						}
					});
				} else {
					let checkedLeg = 0;
					this.tableData.some((item) => {
						if (item.id === val.parent_id) {
							// 获取当前父级下子级选中条数
							const leg = item.children.length;
							checkedLeg = item.children.filter((ss) => ss.is_checked).length;
							// 根据条数改变父级的indeterminate和checked
							if (checkedLeg === 0) {
								item.indeterminate = false;
								item.is_checked = false;
							} else if (checkedLeg < leg) {
								item.indeterminate = true;
								item.is_checked = false;
							} else if (checkedLeg === leg) {
								item.indeterminate = false;
								item.is_checked = true;
							}

							return;
						}
					});
					// console.log(this.tableData2)
				}
				// 判断是否全部选择了,改变全选框的样式
				let flag = true;
				this.tableData.some((item) => {
					if (!item.is_checked) {
						flag = false;
						return;
					}
				});
				this.checkedAll = flag;
			},
		},
	};
</script>
<style scoped>
	.classifyTable /deep/ .el-loading-mask {
		position: absolute;
		height: auto;
	}

	.class_nav {
		background-color: #fff;
	}

	.nav_main {
		padding: 20px;
	}

	.table_head .el-select {
		width: 130px;
		margin-bottom: 20px;
	}

	.table_head .el-select.is-focus {
		border: solid 0.5px var(--fontColor);
		border-radius: 4px;
	}

	.table_head .el-select:focus {
		border: solid 0.5px var(--fontColor);
		border-radius: 4px;
	}

	.table_head .el-select:hover .el-input__inner {
		border: solid 0.5px var(--fontColor);
		border-radius: 4px;
	}

	.table_name {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.table_name span {
		color: #2f74f5;
		background: #e9f0ff;
		padding: 1px 4px;
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		border-radius: 2px;
	}

	.table_list {
		width: 100%;
	}

	.classifyTable table th,
	table td {
		border: none;
	}

	.classifyTable .el-table .el-table__header-wrapper .el-table__header thead tr th {
		background-color: #f9f9fc;
	}

	.classifyTable .el-table .el-table__header-wrapper {
		background-color: #f9f9fc;
	}

	.classifyTable .el-table .el-table__fixed .el-table__fixed-header-wrapper {
		background-color: #f9f9fc;
	}

	.el-table th.el-table__cell {
		background-color: #f9f9fc;
		font-weight: 600;
		color: #333;
		padding: 8px 0;
	}

	.table_icon {
		width: 20px;
		height: 20px;
	}

	.table_icon img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.caozuo a {
		padding: 0 10px;
		border-right: 1px solid var(--fontColor, #fb6638);
		cursor: pointer;
		display: inline-block;
		line-height: 1;
		color: var(--fontColor);
	}

	.caozuo a:last-child {
		border-right: none;
	}

	.empty_data {
		width: 100%;
		padding: 10px 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.empty_data p {
		margin-top: 16px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.4);
		line-height: 20px;
	}

	.batch_wrap {
		margin: 20px 0;
		display: flex;
		justify-content: center;
	}

	.batch_title {
		line-height: 44px;
		margin-right: 20px;
	}

	.batch_url>.el-input>.el-input-group__append {
		padding: 0;
	}

	.batch_url .goodsbtned {
		border-radius: 0 4px 4px 0;
	}

	.goodsbtned {
		margin-right: 0 !important;
	}

	.dialog-footer .goodsbtn {
		margin-right: 8px;
	}

	.dialog-footer .goodsbtn:last-child {
		margin-right: 0;
	}

	.bath_tips {
		color: #d9534f;
		margin: 10px 0;
	}
</style>
